'use client';

import React, { useEffect, useState } from 'react';
import SmallLogo from '@/components/general/LogoSmall';
import Link from 'next/link';
import { trackClick } from '@/helpers/Analytics';
import TextInput from '@/components/general/TextInput';
import Button from '@/components/general/Button';
import i18next from 'i18n';
import { useRouter } from 'next/navigation';
import { CMS } from '@/types/CMS';
import { useMobile } from '@/hooks/useMobile';
import { AnimatePresence, motion } from 'framer-motion';
import ChevronIcon from 'public/assets/icons/chevron-down-grey.svg';
import { InjectDynamicVideo } from '@/components/util/InjectDynamicVideo';
import Container from '../Container';

type IFooterProps = {
    logoUrl: string | null;
    navigation: CMS.Global.Navigation;
    socialLinks: CMS.Global.SocialLink[];
};

const Footer = (props: IFooterProps) => {
    const { navigation, socialLinks, logoUrl } = props;
    const [hologram, setHologram] = useState('');
    const [loaded, setLoaded] = useState<boolean>(false);
    const router = useRouter();
    const isMobile = useMobile();
    const [expanded, setExpanded] = useState<false | number>(0);

    useEffect(() => {
        setLoaded(true);
    }, []);
    return (
        <div className="bg-black text-white md:pb-24 overflow-x-hidden md:px-0 px-[10px] pb-[20px]">
            <Container className="grid md:grid-cols-[22%_22%_22%_22%] grid-cols-[100%] tablet:grid-cols-[15%_17%_17%_17%] col-span-3 gap-12 md:gap-24 py-24 md:text-left text-center leading-9 md:leading-[32px]">
                <div className="md:block flex flex-col items-center">
                    <div className="flex items-center gap-[8px]">
                        {logoUrl ?
                            <img src={logoUrl} width="100" className="" alt="Logo" />
                            :
                            <>
                                <SmallLogo className="" />
                                <h3 className="font-veneer md:text-[1.9rem] text-[1.5rem] whitespace-nowrap hidden xl:block">{i18next.t('footer:the-realest')}</h3>
                            </>}
                    </div>
                    <div className="w-full md:w-auto">
                        <TextInput
                            className="h-[38px] placeholder:text-opacity-50"
                            wrapperClassName="w-full mt-4"
                            type="text"
                            placeholder={i18next.t('footer:search-hologram')}
                            inputPlaceholder={i18next.t('footer:example')}
                            dark
                            onChange={(e) => setHologram(e.currentTarget.value)}
                            value={hologram} />
                        <Button
                            fullWidth
                            className="mt-2"
                            size="sm"
                            disabled={hologram.length < 5}
                            onClick={() => {
                                router.push(`/id/${hologram}`);
                            }}>{i18next.t('footer:search')}
                        </Button>
                    </div>
                </div>
                {loaded && isMobile ? navigation?.footerMenu?.map((category, i) =>
                    <div className="border-b-[1px] pb-[20px] border-[#FFFFFF33]" key={`FooterMenu${category.id}`}>
                        <motion.header
                            className="flex flex-row justify-between items-center"
                            initial={false}
                            onClick={() => setExpanded(i === expanded ? false : i)}
                        >
                            <h3 className="font-secondary text-[20px] font-[500] text-start tracking-[-0.2px] text-white capitalize mb-[14px]">{category.title}</h3>
                            <motion.div initial={false} animate={{ rotate: i === expanded ? 0 : 180 }}>
                                <ChevronIcon />
                            </motion.div>
                        </motion.header>
                        <AnimatePresence initial={false}>
                            {i === expanded && (
                                <motion.section
                                    className="overflow-hidden"
                                    key="content"
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: { height: 'auto' },
                                        collapsed: { height: 0 }
                                    }}
                                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    <ul>
                                        {category.items?.map((item) =>
                                            <li key={`Footer-Inner${item.id}`} className="font-secondary text-left text-[16px] font-[500] tracking-[-0.2px] text-grey15 mb-[14px]">
                                                <Link onClick={trackClick(`Footer:${item.title}`)} href={item.path} target={item.external ? '_blank' : undefined}>
                                                    {item.title}
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                </motion.section>
                            )}
                        </AnimatePresence>
                    </div>) : navigation?.footerMenu?.map((category) =>
                        <div key={`Footer-${category.id}`}>
                            <h3 className="font-secondary text-[16px] font-[500] tracking-[-0.2px] text-white capitalize mb-[14px]">{category.title}</h3>
                            <ul>
                                {category.items?.map((item) =>
                                    <li key={`Footer-Inner${item.id}`} className="font-secondary text-[16px] font-[500] tracking-[-0.2px] text-grey15 mb-[14px]">
                                        <Link onClick={trackClick(`Footer:${item.title}`)} href={item.path} target={item.external ? '_blank' : undefined}>
                                            {item.title}
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>)}
            </Container>
            <div className="max-w-outer mx-auto h-[1px] bg-blue8 mb-[32px] hidden md:block" />
            <div className="flex justify-between flex-col md:flex-row md:items-center max-w-outer mx-auto px-[32px] space-y-[24px] md:space-y-0">
                <span className="text-[16px] font-secondary tracking-[-0.2px] text-blue12">{i18next.t('footer:copyrights', { date: new Date().getFullYear() })}</span>
                <div className="flex flex-row space-x-4 md:my-[10px] md:mb-[20px] items-center">
                    {socialLinks.find((link) => link.social === 'LINKEDIN') && <Link href={socialLinks.find((link) => link.social === 'LINKEDIN')?.url || '#'} target="_blank"><img width="24" height="24" src="/assets/icons/linked-in-white.svg" alt="linkedin" /></Link>}
                    {socialLinks.find((link) => link.social === 'TWITTER') && <Link href={socialLinks.find((link) => link.social === 'TWITTER')?.url || '#'} target="_blank"><img width={14} src="/assets/icons/twitter-white.svg" alt="twitter" /></Link>}
                    {socialLinks.find((link) => link.social === 'INSTAGRAM') && <Link href={socialLinks.find((link) => link.social === 'INSTAGRAM')?.url || '#'} target="_blank"><img src="/assets/icons/instagram-white.svg" alt="instagram" /></Link>}
                </div>
            </div>
            <InjectDynamicVideo />
        </div>
    );
};

export default Footer;
